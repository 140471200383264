<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    scrollable
  >
    <v-card light>
      <v-card-title style="position: relative;">
        Available {{ componentType | capitalizeFirstLetter }}s
        <v-btn
          fab
          absolute
          right
          text
          class="mr-n3"
          @click="$emit('input', false)"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-6">
        <v-list three-line>
          <v-list-item
            v-for="mComponent in mComponents"
            :key="mComponent.id"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ mComponent.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ mComponent.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn
                :loading="adding === mComponent.id"
                @click="addComponent(mComponent.id)"
              >
                <v-icon
                  left
                  color="primary"
                >
                  mdi-plus
                </v-icon>
                Add
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { v1 as uuidv1 } from 'uuid'
export default {
  name: 'MComponentAdder',
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    componentType: {
      type: String,
      required: true
    },
    layoutId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    adding: false
  }),
  computed: {
    mComponents() {
      switch (this.componentType) {
        case 'widget':
          return this.$store.getters.MWidgets
        case 'page':
          return this.$store.getters.MPages
        case 'plugin':
          return this.$store.getters.MPlugins
        default:
          return null
      }
    }
  },
  watch: {
    value(val) {
      this.dialog = val
    }
  },
  methods: {
    async addComponent(componentId) {
      this.adding = componentId
      const organizationId = this.$store.getters.organization.id
      const userId = this.$store.getters.user.uid
      const layoutComponent = {
        createdAt: this.$nowstamp(),
        layoutId: this.layoutId,
        organizationId,
        userId
      }
      layoutComponent[`${this.componentType}Id`] = componentId
      if (this.componentType === 'widget') {
        layoutComponent.gridOptions = {
          h: 1,
          w: 1,
          i: uuidv1(),
          x: 0,
          y: 0
        }
      }
      const { id } = await this.$db.collection(`layout-${this.componentType}s`).add(layoutComponent)
      this.adding = false
      this.$emit('input', false)
      this.$emit('created', id)
    }
  }
}
</script>
