<template>
  <v-card flat max-width="550">
    <v-list>
      <v-list-item
        v-for="layoutPage in layoutPages"
        :key="layoutPage.id"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ layoutPage.name }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="layoutPage.page">
            {{ layoutPage.page.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="flex-row">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="previewComponent('page', layoutPage.id)"
              >
                <v-icon>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>
              Preview Page
            </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="configureLayoutPageId = layoutPage.id"
              >
                <v-icon>
                  mdi-wrench
                </v-icon>
              </v-btn>
            </template>
            <span>
              Configure Page Options
            </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                icon
                :loading="removingPage"
                v-on="on"
                @click="removePage(layoutPage.id)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>
              Remove Page From Layout
            </span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        outlined
        color="primary"
        @click="addPage = true"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Add Page
      </v-btn>
    </v-card-actions>
    <m-component-adder
      v-model="addPage"
      :layout-id="layout.id"
      component-type="page"
      @created="configureLayoutPageId = $event"
    />
    <m-component-configurator
      :layout-item-id="configureLayoutPageId"
      component-type="page"
      @done="configureLayoutPageId = ''"
    />
  </v-card>
</template>

<script>
import MComponentConfigurator from '@backend/components/MComponentConfigurator'
import MComponentAdder from '@backend/components/MComponentAdder'
export default {
  components: {
    MComponentConfigurator,
    MComponentAdder
  },
  data: () => ({
    addPage: false,
    removingPage: false,
    configureLayoutPageId: null
  }),
  computed: {
    layout() {
      return this.$store.getters.layoutById(this.$route.params.id)
    },
    layoutPages() {
      return this.layout ? this.layout.pages || [] : []
    }
  },
  methods: {
    async removePage(layoutPageId) {
      if (confirm('Are you sure you want to delete this item?')) {
        this.removingPage = true
        try {
          await this.$db.collection('layout-pages').doc(layoutPageId).delete()
        } catch (e) {
          alert(JSON.stringify(e))
        }
        this.removingPage = null
      }
    }
  }
}
</script>
