<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    scrollable
    persistent
    :fullscreen="fullscreen"
  >
    <validation-observer
      ref="configuratorForm"
      v-slot="{ valid }"
      slim
    >
      <v-card
        v-if="layoutItemId && mComponent"
      >
        <v-card-title>
          Configure {{ componentTypeName }}
          <v-spacer />
          <v-btn
            fab
            text
            @click="fullscreen = !fullscreen"
          >
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
          <v-btn icon @click="$emit('done')">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card flat>
            <v-card-text>
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Name"
              >
                <v-text-field
                  v-model="name"
                  :label="`${componentTypeName} Name`"
                  :hint="`Give a name to your ${componentTypeName}`"
                  :placeholder="mComponent.name"
                  outlined
                  required
                  :error-messages="errors"
                />
              </validation-provider>
            </v-card-text>
          </v-card>
          <template v-if="layoutItem.name && options">
            <v-divider />
            <v-row class="align-center">
              <v-col class="grow">
                <v-subheader>
                  Component Options
                </v-subheader>
              </v-col>
              <v-col class="shrink mr-5">
                <v-btn
                  v-if="importableOptions.length"
                  text
                  small
                  color="primary"
                  @click="importFrom = true"
                >
                  Import From
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-if="!componentLoaded"
              style="min-height: 300px;"
              justify="center"
              align="center"
            >
              <v-progress-circular
                color="primary"
                indeterminate
              />
            </v-row>
            <component
              :is="`${mComponent.component}-form`"
              v-show="componentLoaded"
              v-model="options"
              :layout-id="layoutId"
              @hook:mounted="componentLoaded = true"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            text
            @click="previewComponent(componentType, layoutItemId)"
          >
            Preview
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="saving"
            :disabled="!valid"
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
    <v-dialog
      v-model="importFrom"
      max-width="360"
    >
      <v-card light>
        <v-card-title style="position: relative;">
          Preconfigured {{ componentTypeName }}s
          <v-btn
            fab
            absolute
            right
            text
            class="mr-n3"
            @click="importFrom = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6">
          <v-list three-line>
            <v-list-item
              v-for="item in importableOptions"
              :key="item.id"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.layout">
                  Used In: {{ item.layout.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn @click="copyOptions(item.options)">
                  <v-icon
                    left
                    color="primary"
                  >
                    mdi-content-copy
                  </v-icon>
                  Copy
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { resolveDefaultOptions } from '@/utils/resolve_widgets'
export default {
  name: 'MComponentConfigurator',
  props: {
    layoutId: {
      type: String,
      default: () => ''
    },
    layoutItemId: {
      type: String,
      default: () => ''
    },
    componentType: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    fullscreen: false,
    name: '',
    options: null,
    saving: false,
    importFrom: false,
    componentLoaded: false
  }),
  computed: {
    layoutItem() {
      if (!this.layoutItemId) {
        return null
      }
      return this.$store.getters[`layout${this.componentTypeName}ById`](this.layoutItemId)
    },
    mComponent() {
      if (!this.layoutItemId) {
        return null
      }
      return this.$store.getters[`M${this.componentTypeName}ById`](this.layoutItem[`${this.componentType}Id`])
    },
    componentTypeName() {
      const firstLetter = this.componentType.substr(0, 1)
      return firstLetter.toUpperCase() + this.componentType.substr(1)
    },
    importableOptions() {
      if (!this.layoutItemId) {
        return []
      }
      const importableOptions = this.$store.getters[`layout${this.componentTypeName}s`].filter((x) => {
        return x[this.componentType].name === this.layoutItem[this.componentType].name && x.id !== this.layoutItemId
      })
      return importableOptions.map((item) => {
        if (item.layoutId) {
          item.layout = this.$store.getters.layoutById(item.layoutId)
        }
        return item
      })
    }
  },
  watch: {
    layoutItemId() {
      if (this.layoutItemId) {
        this.dialog = true
        this.name = this.layoutItem.name
        if (this.layoutItem.options) {
          this.options = JSON.parse(JSON.stringify(this.layoutItem.options))
        } else if (!this.options) {
          this.options = resolveDefaultOptions(this.componentType, this.mComponent.component)
        }
      } else {
        this.name = ''
        this.options = null
        this.dialog = false
      }
    },
    mComponent(component) {
      this.componentLoaded = false
      if (component) {
        this.name = component.name
      }
    }
  },
  methods: {
    copyOptions(options) {
      this.options = JSON.parse(JSON.stringify(options))
      this.importFrom = false
    },
    async save() {
      const savingName = !this.layoutItem.name
      const form = this.$refs.configuratorForm
      const formIsValid = await form.validate()
      if (!formIsValid) { return }
      this.saving = true
      await this.$db.collection(`layout-${this.componentType}s`).doc(this.layoutItem.id).update({
        name: this.name,
        options: this.options,
        updatedAt: this.$nowstamp()
      })
      this.saving = false
      if (!savingName) {
        this.$emit('done')
      }
    }
  }
}
</script>
